@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

@font-face {
  font-family: "Kids-Daddy";
  src: local("Kids-Daddy"),
    url("./fonts/KidsDaddy.ttf") format("ttf"),
    url("./fonts/KidsDaddy.otf") format("opentype");
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 0;
  position: absolute;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.app {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding-bottom: 44px;
}

.app.about {
  flex-direction: row;
  justify-content: center;
}

.app.testimonials {
  display: flex;
  justify-content: center;
  gap: 64px;
  padding-left: 25%;
  padding-right: 25%;
}

.testimonial-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  background-color: #181818;
  padding: 52px 44px;
  border-radius: 32px;
  border: 1px solid #333333;
  transition: all .3s cubic-bezier(.1, .76, .55, .9);
}

.testimonial-container:hover {
  transform: scale(1.05);
}

.testimonial-avatar-container {
  position: relative;
}

.testimonial-avatar {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.2));
}

.testimonial-icon {
  padding: 4px;
  background-color: white;
  border-radius: 8px;
  position: absolute;
  width: 20px;
  left: 0;
  bottom: 0;
}

.testimonial-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
}

.testimonial-comment {
  font-size: larger;
  flex: 3;
  font-style: italic;
}

.testimonial-comment::before {
  content: url(../public/quote.opening.svg);
  display: block;
  width: 44px;
  opacity: 0.1;
  margin-bottom: 12px;
}

.testimonial-comment::after {
  content: url(../public/quote.closing.svg);
  display: block;
  width: 44px;
  opacity: 0.1;
  float: right;
  margin-top: 24px;
}

.testimonial-name {
  font-size: x-large;
  font-weight: bold;
  margin-top: 8px;
}

.testimonial-role {
  font-size: small;
}

.app.resume {
  flex-direction: column;
  justify-content: center;
  height: 0;
}

.wrapper {
  display: grid;
  place-items: center;
}

.profile-container {
  position: relative;
  display: flex;
}

.profile {
  height: 128px;
  width: 128px;
  border-radius: 50%;
  transition: all .3s cubic-bezier(.1, .76, .55, .9);
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
}

.profile:hover {
  transform: scale(1.05) rotate(2deg);
}

.arrow-container {
  position: relative;
}

.arrow {
  position: absolute;
  height: 48px;
  width: 48px;
  transform: rotate(-150deg);
  bottom: -5px;
}

.me-text {
  position: absolute;
  white-space: nowrap;
  bottom: -5px;
  left: 44px;
  font-family: 'Kids-Daddy';
  font-size: x-large;
  transform: rotate(5deg);
}

@keyframes typing {
  from {
    width: 0
  }
}

.name {
  display: block;
  width: 12ch;
  animation: typing 1.25s steps(12);
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: visible;
  font-family: 'Fira Code', monospace;
  font-size: xx-large;
  color: #bfc7d5;
}

.name-dev {
  color: #82aaff;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes blink-no-fade {
  0%, 49% { opacity: 1; }
  50%, 100% { opacity: 0; }
}

.typewriter {
  --caret: currentcolor;
}

.typewriter::after {
  content: "";
  border-right: 1ch solid var(--caret);
  animation: blink 0.8s linear infinite;
  color: #6CA1C5;
}

.small.typewriter::after {
  content: "";
  border-right: 0.3ch solid var(--caret);
  animation: blink-no-fade 1s linear infinite;
  color: #6CA1C5;
}

.pulse {
  --caret: currentcolor;
}

.pulse::after {
  content: "";
  border-right: 1ch solid var(--caret);
  animation: blink 0.8s linear infinite;
}

.avatar {
  height: 192px;
  width: 192px;
  border-radius: 50%;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.2));
  transform: translate3d(0,0,0);
  background-color: white;
}

.company-logo {
  height: 96px;
  width: 96px;
  border-radius: 10%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.company-icon {
  height: 80%;
  width: 80%;
}

.welcome {
  font-size: x-large;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}

.apps-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.apps-title {
  font-family: 'Fira Code', monospace;
  font-size: medium;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: visible;
  color: #000000;
}

.dark .apps-title {
  color: #bfc7d5;
}


.apps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@keyframes fadeInUp {
  to {
      opacity: 1;
  }
}

.mobile-app {
  cursor: pointer;
  transition: all .4s cubic-bezier(.1, .76, .55, .9); 
  margin: 24px;
  align-self: center;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: fadeInUp 0.66s ease-out forwards;
}

.mobile-app:hover {
  transform: scale(1.125);
}

.mobile-app:hover .big-tooltip-container {
  opacity: 1;
  visibility: visible;
  animation: grow 0.4s ease-out forwards;
}

.mobile-app:not(:hover) .big-tooltip-container {
  opacity: 0;
  visibility: hidden;
  animation: shrink 0.2s ease-out forwards;
}

.app-icon-div {
  overflow: hidden;
  height: 112px;
  width: 112px;
  border-radius: 2rem;
  transition: height .8s cubic-bezier(.2,.65,.55,.9);
}

.app-preview-div {
  overflow: hidden;
  height: 243px;
  width: 112px;
  border-radius: 2rem;
  transition: all .8s cubic-bezier(.2,.65,.55,.9);
}

.app-icon {
  overflow: hidden;
  height: 112px;
  width: 112px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.pro-app-icon {
  overflow: hidden;
  height: 80px;
  width: 80px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.app-preview {
  overflow: hidden;
  height: 243px;
  width: 112px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.app-name {
  font-size: large;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}

.button {
  display: flex;
  background-color: #456A7F;
  border-radius: 20px;
  width: 220px;
  height: 50px;
  cursor: pointer;
}

.inner-button {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #6CA1C5;
  border-radius: 20px;
  width: 100%;
  transition: all .2s cubic-bezier(0.1, 0.76, 0.55, 0.9);
  height: 90%;
  font-size: 16pt;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: white;
}

.inner-button:hover {
  margin-bottom: 2.5px;
  margin-top: -2.5px;
}

.inner-button:active {
  margin-bottom: 0;
  margin-top: 0;
}

.footer {
  margin-top: 8px;
  font-family: 'Source Sans Pro', sans-serif;
}

/* unvisited link */
a:link {
  color: currentColor;
  text-underline-offset: 5px;
}

/* visited link */
a:visited {
  color: currentColor;
}

/* mouse over link */
a:hover {
  color: #456A7F;
}

/* selected link */
a:active {
  color: currentColor;
}

.navbar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  z-index: 1;
  padding-top: 32px;
  padding-bottom: 32px;
}

.navbar-select-icon {
  display: none;
}

.navbar-select-mobile {
  display: none;
}

.navbar-button + .navbar-button {
  border-left: solid currentColor 1px;
}

.lang {
  cursor: pointer;
}

.navbar-button {
  padding: 12px;
  font-family: 'Fira Code', monospace;
  transition: 0.5s;
  text-decoration: none;
}

.navbar-button:hover {
  letter-spacing: 2px;
  transition: 0.5s;
}

.navbar-button-active {
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: underline;
  text-underline-offset: 8px;
}

.navbar-buttons {
  display: flex;
}

.terminal {
  margin-left: 32px;
  max-width: 40%;
  min-width: 35%;
  box-shadow: 0 0.25rem 0.5rem #12181e;
  border-radius: 0.5rem;
  overflow: hidden;
  color: white;
  border: 1px solid #333;
}

.terminal-resume {
  max-width: 60%;
  min-width: 40%;
  box-shadow: 0 0.25rem 0.5rem #12181e;
  border-radius: 0.5rem;
  overflow: auto;
  overscroll-behavior: none;
  color: white;
  background: #232323;
  border: 1px solid #333;
}

.terminal-resume-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  margin-top: 32px;
  justify-content: center;
}

.terminal.title {
  width: 14ch;
  min-width: 14ch;
  font-family: 'Fira Code', monospace;
  font-size: xx-large;
  margin-left: 0;
  margin-top: 24px;
  margin-bottom: 24px;
}

.terminal-tabs {
  width: 100%;
  display: flex;
  min-width: 0;
}

.terminal-tab {
  flex: 1;
  font-size: small;
  display: flex;
  min-width: 0;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #383838;
  cursor: pointer;
}

.terminal-tab-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
}

.terminal-tab:hover:not(.selected) {
  background-color: #2E2E2E;
}

.tab-logo {
  height: 50%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  border-radius: 10%;
  background-color: white;
  display: none;
  justify-content: center;
  align-items: center;
}

.tab-icon {
  height: 70%;
  width: 70%;
}

.selected {
  background-color: #232323;
  outline: 1px solid #232323;
  cursor: default;
}

.bar {
  background: #191919;
  height: 36px;
  top: 0;
  display: flex;
  border-bottom: 1px solid #383838;
}

.resume-bar {
  position: sticky;
}

.btn::before, .btn::after, .btn {
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 100%;
  display: block;
}

.btn {
  background: #f6b73e;
  position: relative;
  margin-left: 38px;
  margin-right: 38px;
  top: 12px;
}

.btn::before, .btn::after {
  content: " ";
  position: absolute;
}

.btn::before {
  background: #f55551;
  margin-left: -20px;
}

.btn::after {
  background: #32c146;
  margin-left: 20px;
}

.body {
  background: #232323;
  padding: 18px;
  padding-bottom: 22px;
}

.body.resume-terminal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body.title {
  background: #292D3E;
}

.body pre {
  margin: 0;
  flex: 1;
}

.body .pulse {
  -webkit-animation: pulse 1s ease-in-out infinite;
  -moz-animation: pulse 1s ease-in-out infinite;
  animation: pulse 1s ease-in-out infinite;
}

.prompt {
  flex: 2;
  font-family: 'Fira Code', monospace;
  text-align: left;
}

.prompt-resume {
  font-family: 'Fira Code', monospace;
  font-size: 11pt;
}

.prompt-content {
  text-align: left;
}

.resume-title {
  font-weight: bold;
  text-align: center;
}

.links::before {
  content: "❅ ";
  color: #20C5C6;
}

.links {
  text-align: center;
}

.links::after {
  content: " ❅";
  color: #20C5C6;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.command {
  color: #32c146;
}

.comment {
  opacity: 0.5;
}

.about-text {
  font-family: 'Fira Code', monospace;
  margin-left: 16px;
  width: 300px;
  text-align: left;
}

.country::before {
  content: "Fr";
  color: #6A76FB;
}

.country::after {
  content: "ce";
  color: #fd6f6b;
}

.available {
  color: #67F86F;
}

.not.available {
  color: #fd6f6b;
}

.resume {
  align-self: center;
  margin-top: 32px;
}

@keyframes grow {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  50% {
    transform: scale(1.03);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}


@keyframes shrink {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

.big-tooltip-container {
  position: absolute;
  top: 72.5%;
  background-color: #F2F2F2;
  height: auto;
  width: 224px;
  align-self: flex-start;
  border-radius: 0.5rem;
  transform-origin: top left;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-out, visibility 0.4s ease-out;
  animation: grow 0.4s ease-out forwards;
  overflow: hidden;
}

.dark .big-tooltip-container {
  background-color: #121212;
}

.tooltip-button {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DDDDDD;
}

.tooltip-button:hover {
  background-color: #CCCCCC;
}

.dark .tooltip-button {
  border-bottom: 1px solid #222222;
}

.dark .tooltip-button:hover {
  background-color: #202020;
}


.tooltip-button:last-child {
  border-bottom: none;
}

.tooltip-button:nth-last-child(3) {
  border-bottom: none;
}

.tooltip-separator {
  height: 16px;
  background-color: #E3E3E3;
  border-bottom: none;
}

.dark .tooltip-separator {
  background-color: #0B0B0B;
}

.tooltip-icon {
  width: 16px;
  filter: invert(100%);
}

.dark .tooltip-icon {
  filter: none;
}

@media screen and (max-height: 1000px) {
  .profile {
    height: 96px;
    width: 96px;
  }
}

@media screen and (max-width: 640px) {
  .mobile-app {
    width: 30%;
    margin: 16px;
  }

  .app {
    padding-bottom: 32px;
  }

  .profile {
    height: 64px;
    width: 64px;
  }

  .arrow {
    height: 32px;
    width: 32px;
  }

  .me-text {
    left: 30px;
    bottom: -15px;
  }

  .avatar {
    height: 144px;
    width: 144px;
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.2));
  }

  .app-icon-div {
    height: 64px;
    width: 64px;
    border-radius: 1rem;
  }

  .app-preview-div {
    height: 138.6px;
    width: 64px;
    border-radius: 1rem;
  }

  .app-icon {
    height: 64px;
    width: 64px;
  }

  .pro-app-icon {
    height: 44px;
    width: 44px;
  }

  .app-name {
    font-size: small;
  }

  .app-preview {
    height: 138.6px;
    width: 64px;
  }

  .resume {
    width: 100%;
  }

  .app.about {
    flex-direction: column;
  }

  .terminal {
    min-width: 85%;
    margin-left: 0;
    margin-top: 32px;
  }

  .terminal-tab-title {
    display: none;
  }

  .tab-logo {
    display: flex;
  }

  .prompt-resume {
    font-size: 9pt;
  }

  .company-logo {
    height: 64px;
    width: 64px;
  }

  .terminal-resume {
    max-width: 85%;
  }

  .terminal.title {
    font-size: large;
  }
  
  .name {
    font-size: large;
  }

  .body {
    padding: 14px;
  }

  .navbar {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .navbar-select-icon {
    display: block;
    width: 12px;
  }

  .navbar-select-mobile {
    text-align: center;
    text-align-last: center;
    display: block;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 12px;
    font-family: 'Fira Code', monospace;
    font-weight: bold;
    letter-spacing: 2px;
    text-decoration: underline;
    text-underline-offset: 8px;
    color: var(--text);
  }

  .navbar-button {
    display: none;
  }

  .big-tooltip-container {
    font-size: small;
    width: 184px;
    align-self: center;
    transform-origin: top center;
  }

  .tooltip-button {
    padding: 8px;
  }

  .tooltip-icon {
    width: 14px;
  }

  .app.testimonials {
    padding-left: 10%;
    padding-right: 10%;
  }

  .testimonial-container {
    padding: 32px;
  }

  .testimonial-avatar {
    height: 64px;
    width: 64px;
  }

  .testimonial-icon {
    padding: 2px;
    border-radius: 4px;
    width: 12px;
  }


  .testimonial-name {
    font-size: large;
  }
  
  .testimonial-role {
    font-size: smaller;
  }

  .testimonial-comment {
    font-size: medium;
  }

  .testimonial-comment::before {
    width: 24px;
  }
  
  .testimonial-comment::after {
    width: 24px;
  }
  
}
